import { comfamaDomains } from './comfamaDomains'
/*---------------------------------------------------------------------*/
/* normalizeTwoWords method returns a simplified and normalized 
string joining two words of a given string.
Eg.1:
input: "1. Salón de Juegos" --> output: "1Salon"
Eg.2:
input: "Salón BBQ" --> output: "SalonBBQ"
	Eg.3:
		input: "Salud y cuidado" --> output: "Saludcuidado"
*/
export const normalizeTwoWords = (str) => {
   if (str) {
      let strArr = str.trim().split(" ");
      if (strArr.length > 1) {
       if (strArr.length >= 3) {
        return normalizeCharacters(
          firstUpperOnly(strArr[0]).concat(
             firstUpperOnly(strArr[strArr.length - 1])
          )
        );
      } else {
        return normalizeCharacters(
          firstUpperOnly(strArr[0]).concat(firstUpperOnly(strArr[1]))
        );
      }
    } else {
      return normalizeCharacters(str).trim();
    }
  } else {
    return "";
  }
};
/*---------------------------------------------------------------------*/

export const firstUpperOnly = (str) => {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${
      str.length > 1 ? str.substring(1).toLowerCase() : ""
    }`;
   } else {
    return "";
   }
};

export const normalizeCharacters = (str) => {
   return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/gi, "");
};

export function camelize(str, upper, noAccent, alpha) {
  str = noAccent ? normalizeCharacters(str) : str;
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+|[0-9]+)/g, function (match, index) {
    if (alpha && !isNaN(match)) return "";
    if (+match === 0) return "";
    return !upper && index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const findTarget = (url) => {
   return url && url.startsWith("http") && url.indexOf("www.comfama.com/") === -1 ? "_blank" : "_self";
};

export const findRel = (url) => {
   const followDomainsRegex = new RegExp(comfamaDomains.join('|'), 'i');

   if (url && url.startsWith('http')) {
      if (followDomainsRegex.test(url)) {
         return 'follow';
      } else {
         return 'nofollow noreferrer';
      }
   } else {
      return 'follow';
   }
};

export const formatImageAlt = (altText) => (
  altText && altText.replace(/-/g, " ").replace(/_/g, " ")
)

export const OptimizeImage = (url, type, w) => {
  switch (type) {
    case 'image/jpg':
      return `${url}?w=${w}&fm=jpg&fl=progressive&q=75`
    case 'image/jpeg':
      return `${url}?w=${w}&fm=jpg&fl=progressive&q=75`
    case 'image/png':
      return `${url}?w=${w}&fm=png&fl=png8&q=75`
    case 'image/webp':
      return `${url}?w=${w}&fm=webp&q=75`
    case 'image/svg':
      return `${url}?w=${w}&fm=webp&q=75`
    case 'image/gif':
      return `${url}?w=${w}&fm=webp&q=75`
    default:
      return `${url}?w=${w}&fm=jpg&fl=progressive&q=75`
  }
}

export const handleTwoOptions = (condition, opt1, opt2) => {
  if ( condition ) {
     return opt1
  }
  return opt2
}

export const wordFixerForGTM = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, "_").toLowerCase()
}
